import React from 'react';

const UnlockIcon = () => {
    return (
        <svg width="86" height="72" viewBox="0 0 86 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="35" height="35" rx="17.5" transform="matrix(-1 0 0 1 36 1)" stroke="#00AE99" strokeWidth="2" />
            <rect
                width="83.3824"
                height="35"
                rx="17.5"
                transform="matrix(-1 0 0 1 84.3823 1)"
                stroke="#00AE99"
                strokeWidth="2"
            />
            <rect x="49.3823" y="36" width="35" height="35" rx="17.5" stroke="#00AE99" strokeWidth="2" />
            <rect x="1" y="36" width="83.3824" height="35" rx="17.5" stroke="#00AE99" strokeWidth="2" />
        </svg>
    );
};

export { UnlockIcon };
