export const docs = {
    scrollDuration: 0,
    headerOffset: 116,
    marginBottom: '1.875rem',
    fontSize: {
        mobile: '16px',
        desktop: '17px',
    },
    textColor: '#494949',
};
