import * as React from 'react';

export const GasTickerIcon = () => (
    <svg fill="none" viewBox="0 0 19 18" height="19" width="19" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.1775 1.65597L14.1563 2.67713L15.7502 4.3331V7.22183H17.5556V15.9306C17.5451 16.305 17.2504 16.6102 16.876 16.6102C16.7767 16.6102 16.6834 16.6102 16.6102 16.6102C16.2124 16.6102 15.8987 16.2857 15.888 15.888V10.8328C15.888 9.6362 14.918 8.6662 13.7215 8.6662H12.2771V0H1.44437V17H0V18.7768H13.7215V17H12.2771V10.1106H13.7128C13.7156 10.1106 13.7184 10.1107 13.7213 10.1108C14.1189 10.1201 14.4437 10.435 14.4437 10.8328V15.888C14.4437 17.0846 15.4137 18.0546 16.6102 18.0546C16.6935 18.0546 16.7861 18.0546 16.8851 18.0546C18.0529 18.0546 19 17.1079 19 15.94V5.47848L15.1775 1.65597ZM10.5 7.94402H3.2V1.65597H10.5V7.94402Z"
            fill="black"
        />
    </svg>
);
